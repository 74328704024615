<script setup lang="ts">
import type { BlockHero, BlockButtonGroup } from "~/types";

const props = defineProps<{
  data: BlockHero;
}>();

const config = useRuntimeConfig();
const currentImageIndex = ref(0);
const imagesLoaded = ref(false); // Track when all images are preloaded
const firstImageLoaded = ref(false); // Track when the first image is fully loaded
let intervalId: ReturnType<typeof setInterval> | null = null;

// Compute carousel image URLs
const heroCarouselImages = computed(() =>
  props.data.carousel_items?.map((item) =>
    `${config.public.directus.rest.baseUrl}/assets/${item.directus_files_id.id}?width=1920&format=webp`
  ) || []
);


//compute heights for heros based on data.hero_size small, medium, large
const heroSize = computed(() => {
  if (props.data.hero_size === "small") {
    return "lg:h-[400px] md:h-[200px] sm:h-[200px]";
  } else if (props.data.hero_size === "medium") {
    return "lg:h-[600px] md:h-[600px] sm:h-[600px]";
  } else if (props.data.hero_size === "large") {
    return "lg:h-[800px] md:h-[600px] sm:h-[400px]";
  }
});

//compute text position
const textPosition = computed(() => {
  if (props.data.text_position === "left") {
    return "items-start p-12";
  } else if (props.data.text_position === "right") {
    return "items-end p-12";
  } else if (props.data.text_position === "center") {
    return "items-center justify-center";
  }
});

// Preload images and set state when all are loaded
const preloadImages = () => {
  let loadedCount = 0;
  heroCarouselImages.value.forEach((src) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      loadedCount++;
      if (loadedCount === heroCarouselImages.value.length) {
        imagesLoaded.value = true;
      }
    };
  });
};

// Start image cycling after all images are loaded
const startImageRotation = () => {
  if (heroCarouselImages.value.length > 1) {
    intervalId = setInterval(() => {
      currentImageIndex.value =
        (currentImageIndex.value + 1) % heroCarouselImages.value.length;
    }, 5000); // Show each image for 5 seconds
  }
};

// Handle the first image load separately
const handleFirstImageLoad = () => {
  firstImageLoaded.value = true;
};


//create ref for hero image with default string of w-full h-full object-cover invisible
const heroImageRef = ref('absolute inset-0 w-full h-full object-cover invisible');
//create ref for her text container with default string of relative z-10 flex h-full w-full p-12 text-center items-center justify-center
const heroTextContainerRef = ref('relative invisible z-10 flex h-full w-full p-12 text-center items-center justify-center');
const heroTextContainer2Column = ref('relative invisible z-10 flex h-full w-full p-12');
const heroForegroundImage = ref('invisible');

//we aren't calling the below loader for foreground image but it is here in case we need to utilize the load hook. 
const heroForegroundImageLoader = () => {
  heroForegroundImage.value = 'sm:max-h-[100px] md:max-h-[400px] object-cover rounded-full dark:brightness-90 invisible md:visible';
}
const heroAnimationLoader = () => {
  //set heroImageRef to 'w-full h-full object-cover animate-fade-in'
  heroImageRef.value = 'absolute brightness-50 inset-0 w-full h-full object-cover  animate-fade-in';
  //set heroTextContainerRef to 'relative z-10 flex h-full w-full p-12 text-center items-center justify-center animate-fade-in animate-duration-1000'

  if(props.data.foreground_image){
    heroForegroundImage.value = 'sm:max-h-[100px] md:max-h-[400px] object-cover rounded-full dark:brightness-90 invisible md:visible';
    heroTextContainer2Column.value = 'relative z-10  h-full w-full p-12 animate-ping animate-once  animate-reverse';

  }
  if(!props.data.foreground_image){
    heroTextContainerRef.value = 'relative z-10 flex h-full w-full p-12 text-center items-center justify-center animate-ping animate-once  animate-reverse';
  }
}

// Reactive index to keep track of the current image
onBeforeMount(() => {
  
});

onMounted(() => {
  preloadImages();
  // startImageRotation();
});

onBeforeUnmount(() => {
  if (intervalId) clearInterval(intervalId);
});
watch(imagesLoaded, (loaded) => {
  if (loaded) startImageRotation();
});
</script>
<template>
  
  <BlockContainer full-width remove-padding-y class="relative">
    <div  v-if="data?.hero_type === 'image_and_text'"
    :class="`relative overflow-hidden bg-none ${heroSize}`">
      <NuxtImg
        v-if="data?.image"
        :class="heroImageRef"
        :src="`${data.image.id}`"
        preload
        :alt="data.image?.description ?? data.title ?? 'Hero Image'"
        :title="data.image.description"
        :width="1200"
        @load="heroAnimationLoader()"
        placeholder
      />
      <div :class="heroTextContainerRef" v-if="!data.foreground_image">
          <div class="text-white space-y-6 " role="heading">
            <TypographyHeadline
              v-if="data?.headline"
              :content="data.headline"
              size="title"
              as="h1"
              class="drop-shadow-lg"
            />
            <TypographyTitle
              v-if="data?.title"
              as="h2"
              class="text-white textdrop-shadow-lg my-6 text-xl"
            >
              {{ data.title }}
            </TypographyTitle>
            <p
                class="px-8 prose-lg lg:prose-xl dark:prose-invert prose-headings:font-display prose-headings:font-semibold py-0 dark:text-white text-whited drop-shadow-lg"
              >
                {{ data.content }}
              </p>
            <BlocksButtonGroup
              v-if="data?.button_group"
              :data="data.button_group as BlockButtonGroup"
              class="mt-4"
            />
          </div>
      </div>
      <div :class="heroTextContainer2Column" v-if="data.foreground_image" role="heading">
        
            <TypographyHeadline
            v-if="data?.headline"
            :content="data.headline"
            size="xl"
            as="h1"
            class="mb-4 text-left text-white drop-shadow-lg"
          />
        
        
        <div class="w-full flex justify-center">
            <div class="flex flex-col gap-6 md:flex-row md:items-start mx-auto">
              <div
                v-if="data?.foreground_image"
                class="overflow-hidden border dark:border-gray-700 rounded-full hidden md:block invisible md:visible"
                :class="{
                  'md:order-first': data.image_position === 'left',
                  'md:order-last': data.image_position === 'right',
                }"
              >
                <NuxtImg
                  v-if="data?.foreground_image"
                  :class="heroForegroundImage"
                  :src="data.foreground_image.id as string"
                  :alt="
                    data.foreground_image?.description ??
                    data.title ??
                    'Hero Image'
                  "
                  placeholder
                  width="400"
                  :title="data.foreground_image.description"
                />
                </div>
                <!-- The text content container -->
              <div class="flex-1 items-center">
                <!-- <TypographyTitle v-if="data?.title" class="mb-2">
                  {{ data.title }}
                </TypographyTitle> -->

                <TypographyProse
                  v-if="data?.content"
                  :content="data.content"
                  size="lg"
                  class="py-4 font-display dark:text-white text-white"
                />

                <BlocksButtonGroup v-if="data?.button_group" :data="data.button_group as BlockButtonGroup"/>
              </div>
            

            </div>
          </div>
      </div>
    </div>
    <!-- Image Carousel Hero -->
    <div
      v-if="data?.hero_type === 'image_carousel'"
      :class="`relative overflow-hidden bg-none ${heroSize}`"
    >
    
      <!-- Carousel Image -->
      <NuxtImg
          v-for="(image, index) in data.carousel_items"
          :key="index"
          :src="image.directus_files_id.id"
          preload
          :alt="image.directus_files_id.description ?? data.title ?? 'Hero Image'"
          width="1920"
          class="absolute inset-0 w-full h-full object-cover transition-opacity duration-1500 brightness-50"
          :class="{
            'opacity-100 z-10': index === currentImageIndex && (imagesLoaded || index === 0),
            'opacity-0 z-0': index !== currentImageIndex || !imagesLoaded
          }"
           @load="index === 0 && handleFirstImageLoad()"
           placeholder
        />
      <!-- Carousel Overlay -->
      <!-- Overlay -->
      
      <!-- Text Content -->
      <div class="relative z-10 flex h-full w-full items-center justify-center text-center text-white" role="heading">
        <div class="text-white">
            <!-- <h2 class="mb-4 text-4xl font-semibold">Heading</h2> -->
            <TypographyHeadline
              v-if="data?.headline"
              :content="data.headline"
              size="title"
              as="h1"
            />
            <!-- <h4 class="mb-6 text-xl font-semibold">Subheading</h4> -->
            <TypographyTitle v-if="data?.title" class="text-white">
              {{ data.title }}
            </TypographyTitle>
            <p
              class="px-8 prose-lg lg:prose-xl dark:prose-invert prose-headings:font-display prose-headings:font-semibold py-0 dark:text-white text-white"
            >
              {{ data.content }}
            </p>
            <BlocksButtonGroup
              v-if="data?.button_group"
              :data="data.button_group as BlockButtonGroup"
              class="mt-4"
            />
          </div>
      </div>
    </div>
    
  </BlockContainer>
</template>
<style scoped>
.fade-background {
  transition: background-image 2s ease-in-out;
}
.transition-opacity {
  transition: opacity 1s ease-in-out;
}
</style>
