<script setup lang="ts">
import type { BlockCard, BlockButtonGroup } from '~/types';

const props = defineProps<{
  data: BlockCard;
}>();

const UICardconfig = {
  wrapper:
    'relative group isolate rounded-xl background-gradient ring-1 ring-primary-200 dark:ring-primary-800 before:hidden before:lg:block before:absolute before:-inset-[2px] before:h-[calc(100%+6px)] before:w-[calc(100%+6px)] before:z-[-1] before:rounded-[13px] flex-1 flex flex-col shadow',
  to: 'hover:ring-totem-pole-500 dark:hover:ring-totem-pole-400  transition-shadow duration-200',
  base: 'flex-1 flex flex-col overflow-hidden ',
  container: '',
  body: {
    base: 'gap-x-8 gap-y-4 rounded-xl flex-1 flex flex-col  min-h-[257px]',
  },
  background: 'bg-white dark:bg-white hover:bg-opacity-90 dark:hover:bg-opacity-90 transition-[background-opacity] ',
  ring: 'primary',
  rounded: 'rounded-xl',
  shadow: '',
  icon: {
    wrapper: 'mb-2 pointer-events-none text-center',
    base: 'w-8 h-8 flex-shrink-0 text-totem-pole-500 dark:text-totem-pole-500 ',
  },
  title: 'text-black dark:text-black text-base font-bold truncate',
  description: 'text-[15px] text-black dark:text-black mt-1',
};

const customIcon = computed(() => {
  //if data.custom icon is not empty, use it, else use data.icon
  //we need to prepend material-symbols: to the default data.icon data
  if (!props.data.custom_icon && !props.data.icon) {
    return "";
  } else if(props.data.custom_icon) {
    return props.data.custom_icon;
  } else {
    return `material-symbols:${props.data.icon}`;
  }
});
</script>
<template>
  <ULandingCard v-if="data"
    :title="data.title"
    :description="data.description"
    :icon="`${customIcon}`"
    color="primary"
    :ui="UICardconfig"
    :orientation="data?.orientation"
  >
    <!-- <img
      src="https://picsum.photos/640/360?grayscale"
      class="w-full rounded-md"
    /> -->
    <BlocksButtonGroup v-if="data.button_group" :data="data.button_group as BlockButtonGroup" />
    <NuxtImg
      v-if="data?.image"
      class="w-full overflow-hidden dark:brightness-90 max-h-[500px] w-full rounded-md"
      :src="(data.image.id as string)"
      :alt="data.image?.description ?? data.title ?? 'Hero Image'"
      :title="data.image.description"
      width="500"
      placeholder
    />
  </ULandingCard>
</template>
