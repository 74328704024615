<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { BlockCardGrid } from '~/types';

const props = defineProps<{
  data: BlockCardGrid;
}>();

// function xformUI(ui) {
//   console.log('what is ui', JSON.parse(ui));
//   return JSON.parse(ui);
// }
const UIconfig = computed(() => {
  if (props.data.ui) {
    return JSON.parse(props.data.ui);
  }
});
</script>
<template>
  <BlockContainer full-width class="mx-10 py-20 bg-[#141414] dark:bg-[#141414]">
    <UContainer>
      <!-- <TypographyTitle v-if="data.title">{{ data.title }}</TypographyTitle> -->
      <TypographyHeadline v-if="data.title" :content="data.title" size="lg" class="text-white" />
    </UContainer>
    <br />
    <UContainer v-if="data.cards" class="mb-6">
      <!-- <pre>{{ data.cards }}</pre> -->
      <ULandingGrid :ui="UIconfig">
        <span v-for="card in data.cards" :key="card.id">
          <BlocksLandingCard :data="card" v-if="card"/>
        </span>
      </ULandingGrid>
    </UContainer>
  </BlockContainer>
</template>
