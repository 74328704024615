<script setup lang="ts">
import { useDebounceFn } from '@vueuse/shared';
import type { BlockTestimonial, BlockTestimonialItem, Testimonial, File } from '~/types';
import { markdownToHtml } from '~/utils/markdown';

const props = defineProps<{
  data: BlockTestimonial;
}>();

const offset = ref(0);
const limit = ref(5);

function loadMore() {
  limit.value += 5;
}

const testimonials = computed(() => {
  const testimonialsArray = unref(props.data).testimonials as BlockTestimonialItem[];

  if (!testimonialsArray) return [];

  return testimonialsArray.map((item) => {
    return item.testimonials_id as Testimonial;
  });
});

const testimonial_header = computed(() => {
  if (props.data?.description && props.data.layout_type === 'masonry') {
    return props.data.description.replace(/<\/?p>/g, '');
  } else if (props.data?.headline) {
    return props.data.headline;
  } else {
    return '';
  }
});

const testimonials_masonry = computed(() => {
  const testimonialsArray = unref(props.data).testimonials as BlockTestimonialItem[];

  if (!testimonialsArray) return [];

  return testimonialsArray.slice(offset.value, limit.value).map((item) => {
    //remove <p> and </p> tag from content
    item.testimonials_id.content = item.testimonials_id.content.replace(/<\/?p>|<br\s*\/?>/g, '');
    return {
      quote: item.testimonials_id.content,
      author: {
        name: item.testimonials_id.title,
        description: item.testimonials_id.company,
        avatar: {
          src: '',
        },
      },
    };
  });
});

const testimonialContainer: Ref<HTMLElement | null> = ref(null);
const testimonialRefs: Ref<(HTMLElement | null)[]> = ref([]);

const currentItemIdx = ref(0);

function handleScroll(e: Event) {
  const target = e.target as HTMLElement;

  if (!testimonialContainer.value || !testimonialRefs.value) return;

  const testimonialWidth = testimonialRefs.value[0]?.offsetWidth || 0;
  const testimonialCenter = testimonialWidth / 2;
  const scrollLeft = target.scrollLeft;
  const scrollCenter = scrollLeft + testimonialCenter;
  const closestTestimonial = Math.round(scrollCenter / testimonialWidth);

  if (scrollLeft === 0) {
    currentItemIdx.value = 0;
  } else if (scrollLeft + target.offsetWidth + 1 >= target.scrollWidth) {
    currentItemIdx.value = testimonialRefs.value.length - 1;
  } else {
    currentItemIdx.value = closestTestimonial;
  }
}

const debouncedScroll = useDebounceFn(handleScroll, 150);

function handleScrollDebounced(e: Event) {
  debouncedScroll(e);
}

function handleIndicatorButton(index: number) {
  if (!testimonialContainer.value || !testimonialRefs.value) return;

  const scrollLeft = testimonialContainer?.value?.scrollLeft;
  const offsetLeft = testimonialRefs?.value[index]?.offsetLeft;

  if (typeof scrollLeft !== 'undefined' && typeof offsetLeft !== 'undefined') {
    testimonialContainer.value.scrollLeft = offsetLeft - 16;
  }
}

function handleNavButton(direction: 'left' | 'right') {
  if (!testimonialContainer.value || !testimonialRefs.value) return;

  const scrollLeft = testimonialContainer?.value?.scrollLeft;
  const offsetWidth = testimonialRefs?.value[currentItemIdx?.value]?.offsetWidth;

  if (typeof scrollLeft !== 'undefined' && typeof offsetWidth !== 'undefined') {
    if (direction === 'left') {
      testimonialContainer.value.scrollLeft -= offsetWidth;
    } else {
      testimonialContainer.value.scrollLeft += offsetWidth;
    }
  }
}

//config for masonry layout
const masonry_config = {
  body: {
    base: 'flex flex-col dark:bg-submarine-800 shadow-md transition duration-300 hover:ring-1 hover:ring-totem-pole-500 hover:shadow-lg hover:shadow-totem-pole-500/50 dark:hover:ring-totem-pole-100 dark:hover:shadow-totem-pole-200/50',
    to: '',
    padding: 'px-4 py-5 sm:p-6',
  },
  wrapper: 'relative animate-fade-in animate-grow-in ',
  quote: 'text-submarine-950 dark:text-white',
  icon: {
    wrapper: 'mb-6 flex',
    base: 'w-8 h-8 flex-shrink-0 text-black dark:text-white',
  },
  author: {
    wrapper: 'flex items-center gap-3 mt-6 relative',
    name: 'font-semibold text-black dark:text-white text-sm',
    description: 'text-submarine-900 dark:text-submarine-400 text-sm',
    avatar: {
      base: '',
      size: 'md',
    },
  },
};
</script>
<template>
  <BlockContainer v-if="data.layout_type === 'slider'" class="relative overflow-hidden" full-width>
    <!--bg-gradient-to-b from-blue-zodiac-100 via-blue-zodiac-200 to-blue-zodiac-300 dark:from-midnight-blue-500 dark:via-midnight-blue-700 dark:to-midnight-blue-900 -->
    <div class="absolute inset-0 bg-[#b4c1c5] dark:bg-[#333333]" />
    <div class="absolute inset-0 opacity-50 grain-bg dark:opacity-10" />

    <div class="relative text-center">
      <TypographyTitle v-if="data.title">{{ data.title }}</TypographyTitle>
      <TypographyHeadline v-if="data.headline" :content="data.headline" size="lg" />
    </div>

    <div v-if="testimonials.length > 0" class="relative mt-4">
      <div class="flex items-center px-6 space-x-8 lg:px-16">
        <div class="w-1/2">
          <UButton
            icon="material-symbols:open-in-new"
            size="lg"
            square
            class="text-white dark:text-white inline-flex"
            to="/client-testimonials"
          >
            See All Testimonials
          </UButton>
        </div>
        <!--container for right side two-->
        <div class="w-1/2 inline-flex justify-end items-center px-0">
          <div class="md:inline-flex md:space-x-2 invisible md:visible">
            <button
              v-for="(item, itemIdx) in testimonials"
              :key="item.id"
              :class="[
                {
                  'bg-primary': itemIdx === currentItemIdx,
                  'bg-gray-900 bg-opacity-50 dark:bg-gray-500 ': itemIdx !== currentItemIdx,
                },
              ]"
              class="flex items-center justify-center w-12 h-3 rounded-button hover:opacity-75 disabled:opacity-50 disabled:cursor-not-allowed invisible md:visible"
              aria-label="Testimonial Indicator"
              @click="handleIndicatorButton(itemIdx)"
            />
          </div>

          <div class="ml-2 flex gap-2 justify-self-end">
            <UButton
              :disabled="currentItemIdx === 0"
              icon="material-symbols:arrow-back-rounded"
              size="lg"
              square
              class="text-white dark:text-white"
              aria-label="Previous Testimonial"
              @click="handleNavButton('left')"
            />
            <UButton
              :disabled="currentItemIdx === testimonials.length - 1"
              icon="material-symbols:arrow-forward-rounded"
              size="lg"
              square
              class="text-white dark:text-white"
              aria-label="Next Testimonial"
              @click="handleNavButton('right')"
            />
          </div>
        </div>
      </div>
      <div
        ref="testimonialContainer"
        class="flex w-full px-4 py-6 space-x-6 overflow-x-auto md:px-6 lg:px-16 scrollbar-hide md:pt-8 snap-x scroll-smooth"
        @scroll="handleScrollDebounced"
      >
        <div
          v-for="testimonial in testimonials as Testimonial[]"
          :key="testimonial.id"
          ref="testimonialRefs"
          :class="['snap-center']"
          class="relative w-[350px] md:w[450px] lg:w-[600px] flex flex-col justify-between flex-shrink-0 p-8 bg-white dark:bg-gray-900 shadow-md rounded-card overflow-hidden"
        >
          <UIcon
            name="material-symbols:format-quote-rounded"
            class="absolute w-20 h-20 rotate-180 left-2 text-primary/20 top-2"
          />
          <TypographyProse v-if="testimonial?.content" :content="testimonial?.content" size="md" class="relative" />
          <div class="flex pt-6 mt-4 space-x-2 border-t border-gray-300 dark:border-gray-700">
            <!-- Person Image -->
            <NuxtImg
              v-if="(testimonial?.image as unknown as File)?.id"
              class="inline-block w-16 h-16 border rounded-button"
              :src="(testimonial?.image as unknown as File)?.id"
              :alt="testimonial.title ?? ''"
              placeholder
            />
            <UIcon
              v-else
              name="material-symbols:account-circle"
              class="inline-block w-16 h-16 text-gray-300 border rounded-button"
            />

            <div class="relative">
              <p v-if="testimonial.title" class="font-semibold text-primary font-display lg:text-2xl">
                {{ testimonial.title }}
              </p>
              <p v-if="testimonial.company" class="text-sm text-gray-700 lg:text-lg dark:text-gray-300">
                {{ testimonial.subtitle }} at {{ testimonial.company }}
              </p>
              <p v-else class="text-sm text-gray-700 lg:text-lg dark:text-gray-300">
                {{ testimonial.subtitle }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BlockContainer>
  <BlockContainer v-if="data.layout_type === 'masonry'" full-width class="bg-submarine-500 dark:bg-submarine-950">
    <ULandingSection :title="data.title" :description="testimonial_header">
      <UPageColumns>
        <!-- Hack for Safari -->
        <div v-for="(testimonial, index) in testimonials_masonry" :key="index" class="break-inside-avoid">
          <ULandingTestimonial v-bind="testimonial" :ui="masonry_config" />
        </div>
      </UPageColumns>
    </ULandingSection>
    <UContainer>
      <UButton
        v-if="data.layout_type === 'masonry' && data.testimonials.length > limit"
        variant="solid"
        size="xl"
        class="text-white dark:text-white"
        @click="loadMore"
      >
        Load More
      </UButton>
    </UContainer>
  </BlockContainer>
</template>
